@font-face {
  font-family: Gentona;
  src: url("./fonts/Gentona.otf") format("opentype");
}

@font-face {
  font-family: CovesBold;
  src: url("./fonts/CovesBold.otf") format("opentype");
}

html {
  height: 100%;
}
body {
  padding: 0px;
  margin: 0px;
	height: 100%;
  /*background-repeat: no-repeat;
	background-image: linear-gradient(#097b67, #07d886);*/
	background: -webkit-gradient(linear, left top, left bottom, from(#097b67), to(#07d886)) fixed;
}

.container {
  display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-content: center;
    height: 100vh;
    grid-auto-rows: auto;
}

.illustration {
  display: grid;
  justify-content: center;
}

.lottie {
	width: 300px!important;
  height: 190px!important;
}

.header {
  display: grid;
  justify-content: center;
}

.text-content {
  display: grid;
  justify-content: center;
}

h1 {
  font-family: "Gentona";
  color: rgb(223 223 223);
  font-size: 34px;
}

h4 {
  font-family: "Gentona";
  color: rgb(32 121 99);
  font-size: 20px;
  margin-bottom: 5px;
}

.social-content {
  padding: 15px;
  display: grid;
  justify-content: center;
  grid-column-gap: 10px;
  grid-auto-flow: column;
}
.social-logo {
  width: 35px;
  height: 35px;
  filter: opacity(90%);
  color: rgb(223 223 223);
  transition-duration: 0.3s;
}
.social-logo:hover {  
  filter: opacity(100%);
  transition-duration: 0.5s;
}
.social-logo:active {
  filter: brightness(97%);
  transition-duration: 0.5s;
}
::selection {
  background: rgb(25 99 81);
  color: #fff;
  opacity: 0.5;
}

::-moz-selection {
  background: rgb(25 99 81);
  color: rgb(252, 252, 252);
}
